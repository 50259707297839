<template>
  <v-dialog v-model="dialog" max-width="450px" persistent>
    <v-card class="pa-4">
      <!-- <v-toolbar
        height="80"
        :color="error ? 'error' : 'primary'"
        dark
        class="mb-5"
      >
        <v-toolbar-title style="fontSize:20px;fontWeight:bold">
          {{ formTitle }}
        </v-toolbar-title>
      </v-toolbar> -->
      <v-card-title style="position:relative">
        <v-btn
          icon
          @click="close"
          style="position:absolute;top:0px;left:0px"
          class="error--text"
          x-small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <div class="auth-title text-center">
          <div
            style="width: 35px;height:35px;borderRadius:50%;border: 2px solid black"
            class=""
          >
            <v-icon>mdi-lock</v-icon>
          </div>
        </div> -->
        <h2
          style="width:100%"
          class="h6 auth-title font-weight-light  text-center"
        >
          <v-icon>mdi-lock</v-icon>
          <!-- {{ formTitle }} -->
        </h2>
      </v-card-title>
      <v-card-text>
        <v-slide-y-transition v-if="showSignin">
          <v-form ref="form" v-model="valid">
            <v-row class="fill-height mt-3" align="center" justify="center">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="loginForm.email"
                  :rules="emailRules"
                  :label="$t('email')"
                  outlined
                  required
                ></v-text-field>
                <!-- prepend-icon="mdi-email" -->
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="loginForm.password"
                  :rules="textRules"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('password')"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  required
                ></v-text-field>
                <!-- prepend-icon="mdi-lock" -->
              </v-col>
            </v-row>
          </v-form>
        </v-slide-y-transition>

        <v-slide-y-reverse-transition v-if="showSignup">
          <v-form ref="form" v-model="valid">
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.first_name"
                  :rules="textRules"
                  :label="$t('fname')"
                  prepend-icon="mdi-account"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.last_name"
                  :rules="textRules"
                  :label="$t('lname')"
                  prepend-icon="mdi-account"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.email"
                  :rules="emailRules"
                  :label="$t('email')"
                  prepend-icon="mdi-email"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-select
                  :items="countries"
                  :label="$t('country')"
                  v-model="signupForm.country_id"
                  :loading="loading"
                  :item-text="[$root.lang + 'name']"
                  prepend-icon="mdi-map-marker"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'field is required']"
                ></v-select>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.password"
                  :rules="textRules"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('password')"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signupForm.confirmPassword"
                  :rules="confirmPasswordRules"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('confirmPassword')"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-slide-y-reverse-transition>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <div style="width:90%;">
          <v-btn
            style="width:100%; height: 45px;lineHeight:70px"
            class="primary white--text rounded-lg d-flex justify-center align-center"
            @click="submit"
            text
          >
            <v-progress-circular
              class="mr-2"
              color="white"
              indeterminate
              size="25"
              v-if="submitted"
            ></v-progress-circular>
            <h3 class="ma-0 pa-0 overflow-hidden">
              {{ showSignin ? $t('AUTH.login') : $t('AUTH.signup') }}
            </h3>
          </v-btn>
        </div>
        <div class="mt-3 d-flex justify-flx-end">
          <div>
            <span style="fontSize:14px" class="mx-1">{{
              showSignin ? $t('AUTH.no_account') : $t('AUTH.have_account')
            }}</span>

            <v-btn
              class="pa-0"
              text
              color="primary"
              @click="
                showSignin = !showSignin;
                showSignup = !showSignup;
              "
              >{{ !showSignin ? $t('AUTH.login') : $t('AUTH.signup') }}</v-btn
            >
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
      timeout="-1"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: ['record', 'dialog'],
  data() {
    return {
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: '',
      snackbarMsg: null,
      snackbarColor: '',
      formTitle: 'Login',
      showSignin: true,
      showSignup: false,
      showPassword: false,

      countries: [],

      loginForm: {
        email: '',
        password: '',
      },
      signupForm: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        country_id: '',
      },
      cycle: false,

      textRules: [(v) => !!v || 'field is required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || 'E-mail must be valid';
        },
      ],

      confirmPasswordRules: [
        (v) => !!v || 'Confirm Password is required',
        (v) => v === this.signupForm.password || 'password must be matched',
      ],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    ...mapActions(['login', 'signup']),

    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          if (this.showSignin) {
            const authLogin = await this.login(this.loginForm);
            console.log({ authLogin });

            if (this.userGetter.errorLogin) {
              this.snackbarMsg = this.userGetter.errorLogin;
              this.snackbarColor = 'error';
              this.snackbar = true;
            }
            this.userGetter.currentUser && this.close();
          } else if (this.showSignup) {
            delete this.signupForm['confirmPassword'];
            this.signupForm['status'] = 'active';
            this.signupForm['role'] = 3;
            await this.signup(this.signupForm);

            if (this.userGetter.errorSingup) {
              this.snackbarMsg = this.userGetter.errorSingup;
              this.snackbarColor = 'error';
              this.snackbar = true;
            } else {
              this.loginForm['email'] = this.signupForm.email;
              this.loginForm['password'] = this.signupForm.password;
              this.showSignup = false;
              this.showSignin = true;
            }
          }

          this.submitted = false;
          const msg = this.record ? 'recordUpdated' : 'recordInserted';
          this.$emit('formSubmitted', msg);
        } else {
          // this.error = 'fillRequiredFields';
          this.snackbarMsg = 'fillRequiredFields';
          this.snackbarColor = 'error';
          this.snackbar = true;
        }
      } catch (error) {
        if (error.message !== '') {
          this.error = error;
          this.snackbarColor = 'error';
        } else {
          // this.error = 'somethingWentWrong';
          this.snackbarMsg = 'somethingWentWrong';
          this.snackbarColor = 'error';
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },

    async fetchCountries() {
      try {
        this.loading = true;
        const { data } = await ApiService.get('items/countries');
        this.countries = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.$emit('closeDialog');
    },
  },
  computed: {
    ...mapGetters(['userGetter']),
  },

  mounted() {
    this.record && Object.assign(this.loginForm, this.record);
    Object.assign(this.initialPayload, this.record);
    this.fetchCountries();
  },
};
</script>

<style scoped>
.auth-title {
  /* font-size: 4rem; */
  /* margin: auto; */
}
.auth-title::before,
.auth-title::after {
  display: inline-block;
  content: '';
  border-top: 0.5px solid #ddd;
  width: 31%;
  margin: 0 0.5rem;
  transform: translateY(-0.4rem);
}
</style>
